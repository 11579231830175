import classnames from "classnames/bind";
import { useId } from "react";

import { Chip } from "../../Chip/Chip";
import { useUiContext } from "../../uiContext/useUiContext";
import styles from "./SelectedItems.module.scss";
const cx = classnames.bind(styles);

type Props<T> = {
  id: string;
  selectedItems: T[];
  disabled?: boolean;
  getItemId?: (item: T) => string;
  getDisplayName: (item: T) => string;
  onRemove: (item: T) => void;
  isRemovable?: (item: T) => boolean;
};

export const SelectedItems = <T,>(props: Props<T>) => {
  const id = useId();

  const {
    selectedItems,
    getDisplayName,
    getItemId = () => id,
    isRemovable = () => true
  } = props;
  const { translation } = useUiContext();
  return (
    <>
      {selectedItems.length > 0 && (
        <ul className={cx("design-multiple-list")} id={props.id}>
          <span className="sr-only">
            {translation.autocomplete.selectedItems}
          </span>

          {selectedItems.map((item) => (
            <li key={getItemId(item)}>
              <Chip
                className={cx("design-multiple-list-chip")}
                isRemovable={props.disabled || isRemovable(item)}
                removeAriaLabel={getDisplayName(item)}
                onRemoveClick={() => props.onRemove(item)}
              >
                {getDisplayName(item)}
              </Chip>
            </li>
          ))}
        </ul>
      )}
    </>
  );
};
