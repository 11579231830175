"use client";

import * as Tabs from "@radix-ui/react-tabs";
import classNames from "classnames/bind";
import { Children, cloneElement, ReactElement, RefAttributes } from "react";

import { isChildTabElement, TabProps } from "./Tab";
import styles from "./tab.module.scss";

const cx = classNames.bind(styles);

export type TabGroupProps = {
  /**
   * The value of the tab to activate by default. Defaults to the first non-disabled tab if not specified.
   */
  defaultValue?: string;
  /**
   * Position of the tabs. Defaults to left.
   */
  position?: "left" | "center" | "right";
} & Omit<Tabs.TabsProps, "defaultValue"> &
  RefAttributes<HTMLDivElement>;

export function TabGroup({
  children,
  defaultValue,
  position = "left",
  ...tabGroupProps
}: TabGroupProps) {
  const childrenWithValues = Children.map(children, (child, index) =>
    cloneElement(child as ReactElement, {
      value: (child as ReactElement).props.value || index.toString()
    })
  );

  const childrenArray = Children.toArray(childrenWithValues) as ReactElement[];

  if (childrenArray.some((child) => !isChildTabElement(child))) {
    throw new Error("TabGroup can only contain children of type Tab");
  }

  if (childrenArray.length === 0) {
    throw new Error("TabGroup must have at least one Tab-element");
  }

  const childrenProps = childrenArray.map((child) => child.props as TabProps);
  const firstNonDisabledChildValue = childrenProps.find(
    ({ disabled }) => !disabled
  )?.value;

  return (
    <Tabs.Root
      defaultValue={defaultValue ?? firstNonDisabledChildValue}
      {...tabGroupProps}
    >
      <Tabs.List className={cx("tab-list", position)}>
        {childrenProps.map(({ tabLabel, value, ...props }, index) => (
          <Tabs.Trigger
            className={cx("tab-trigger")}
            key={value}
            value={value ?? `a-${index}`}
            {...props}
          >
            {tabLabel}
          </Tabs.Trigger>
        ))}
      </Tabs.List>
      {childrenWithValues}
    </Tabs.Root>
  );
}
