import { MutableRefObject, Ref, RefCallback, useCallback } from "react";

/**
 * Used when you need to pass multiple refs, to a single element. Will return
 * a single ref.
 *
 * const mergedInputRef = useMergedRef(inputRef, ref);
 * ...
 * <input ref={mergedInputRef} />
 * ...
 */
export function useMergedRef<T>(...refs: Ref<T>[]): RefCallback<T> {
  return useCallback((element: T) => {
    for (const ref of refs) {
      if (typeof ref === "function") ref(element);
      else if (ref && typeof ref === "object")
        (ref as MutableRefObject<T>).current = element;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, refs);
}
