import { Separator } from "@radix-ui/react-dropdown-menu";
import classnames from "classnames/bind";

import styles from "./DropdownMenu.module.scss";

const cx = classnames.bind(styles);

export type DropdownMenuDividerProps = {
  className?: string;
};

export function DropdownMenuDivider({ className }: DropdownMenuDividerProps) {
  return <Separator className={cx("dropdown-divider", className)} />;
}
