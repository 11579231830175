"use client";

import { Content, TabsTriggerProps } from "@radix-ui/react-tabs";
import classNames from "classnames/bind";
import { ReactElement, ReactNode, RefAttributes } from "react";

import styles from "./tab.module.scss";

const cx = classNames.bind(styles);

export type TabProps = {
  /**
   * The title of the tab.
   */
  tabLabel: ReactNode;
  /**
   * The controlled value of the tab to activate. If not specified, index of the tab will be used.
   */
  value?: string;
} & Omit<TabsTriggerProps, "value"> &
  RefAttributes<HTMLButtonElement>;

export function isChildTabElement(
  child: ReactElement
): child is ReactElement<TabProps> {
  const props = child.props as TabProps;
  return props.tabLabel !== undefined;
}

export function Tab({ value, children, className }: TabProps) {
  return (
    <Content className={cx("tab-content", className)} value={value as string}>
      {children}
    </Content>
  );
}
