import { useCallback, useEffect, useMemo, useState } from "react";

export const useKeyboardSelect = <T>(
  isOpen: boolean,
  items: T[],
  onSelectedValue: (item: T) => void,
  setIsOpen: (isOpen: boolean) => void,
  getItemId: (item: T) => string,
  inputElement: HTMLInputElement | null
) => {
  const [activeIndex, setActiveIndex] = useState<number | undefined>();

  const activeItem = useMemo(
    () => (activeIndex ? items[activeIndex] : undefined),
    [activeIndex, items]
  );

  const activeDescendant = activeItem ? getItemId(activeItem) : undefined;

  useEffect(() => {
    setActiveIndex(undefined);
  }, [items]);

  useEffect(() => {
    if (activeItem) {
      const activeElement = getItemId(activeItem);
      const el = document.querySelector(`#${activeElement}`);
      el?.scrollIntoView();
    }
  }, [activeItem, getItemId]);

  const onKeyDown = useCallback(
    // eslint-disable-next-line max-statements
    (event: KeyboardEvent) => {
      const finalIndex = items.length - 1;

      switch (event.key) {
        case "Enter": {
          if (activeIndex !== undefined && isOpen) {
            event.preventDefault();
            onSelectedValue(items[activeIndex]);
          }
          break;
        }
        case "ArrowDown": {
          event.preventDefault();
          setIsOpen(true);
          setActiveIndex((index) =>
            index === undefined || index === finalIndex ? 0 : index + 1
          );
          break;
        }
        case "ArrowUp": {
          event.preventDefault();
          setIsOpen(true);
          setActiveIndex((index) =>
            index === undefined || index === 0 ? finalIndex : index - 1
          );
        }
      }
    },
    [activeIndex, items, isOpen, onSelectedValue, setIsOpen]
  );

  useEffect(() => {
    inputElement?.addEventListener("keydown", onKeyDown);
    return () => {
      inputElement?.removeEventListener("keydown", onKeyDown);
    };
  }, [inputElement, onKeyDown]);

  return { activeIndex, activeDescendant };
};
