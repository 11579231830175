import { ExpandedState } from "@tanstack/react-table";
import { useState } from "react";

import { Row, TableProps } from "../tableProps";

/**
 * A group of handlers and states regarding expanding and collapsing of rows.
 */
export function useExpanding<T>(props: TableProps<T>) {
  const [expandedState, setExpandedState] = useState<ExpandedState>({});

  function getExpandedRowIds(expandFn: (old: ExpandedState) => ExpandedState) {
    const futureExpandedState = expandFn(expandedState);

    const pre = Object.keys(expandedState);
    const post = Object.keys(futureExpandedState);

    const expanded = post.find((x) => !pre.includes(x));
    const collapsed = pre.find((x) => !post.includes(x));

    setExpandedState(futureExpandedState);

    return { expandedRowIds: post, expanded, collapsed };
  }

  function getRowCanExpand(row: Row<T>) {
    const isExpandable = props.getSubRows || props.renderExpandable;
    if (isExpandable)
      return props.isRowExpandable ? props.isRowExpandable(row) : true;
    return false;
  }

  return { getRowCanExpand, getExpandedRowIds, expandedState };
}
