"use client";

import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as AccordionPrimitive from "@radix-ui/react-accordion";
import classNames from "classnames/bind";
import { ReactNode, useId } from "react";

import styles from "./Accordion.module.scss";

const cx = classNames.bind(styles);

export type AccordionSingleProps = {
  /**
   * Controls whether multiple AccordionItem's can be expanded at once, or if one close by the next one opening.
   *
   * Optional; defaults to multiple.
   *
   */
  type: "single";
  /**
   * Controls which AccordionItem(s) is expanded by default. Defaultvalues type is either string or stringarray depending on "single or multiple" type accordion. Requires that the user set's the value prop for the AccordionItem.
   *
   * Optional, undefined by default.
   */
  defaultValue?: string;
  value?: string;
  className?: string;
  children: ReactNode;
  onValueChange?: (value: string) => void;
};

export type AccordionMultipleProps = {
  type?: "multiple";
  defaultValue?: string[];
  value?: string[];
  className?: string;
  children: ReactNode;
  onValueChange?: (value: string[]) => void;
};

export type AccordionProps = AccordionSingleProps | AccordionMultipleProps;

export function Accordion({
  children,
  className,
  type,
  defaultValue,
  onValueChange,
  value
}: AccordionProps) {
  if (type === "single") {
    return (
      <AccordionPrimitive.Root
        className={className}
        type="single"
        defaultValue={defaultValue}
        value={value}
        onValueChange={onValueChange}
        collapsible
      >
        {children}
      </AccordionPrimitive.Root>
    );
  }

  return (
    <AccordionPrimitive.Root
      className={className}
      type="multiple"
      defaultValue={defaultValue}
      value={value}
      onValueChange={onValueChange}
    >
      {children}
    </AccordionPrimitive.Root>
  );
}

export type AccordionItemProps = {
  /**
   * A unique value for the item that can be used to determine which item is expanded by default.
   *
   *  Optional; defaults to a random id.
   */
  value?: string;
  className?: string;
  children: ReactNode;
};

export function AccordionItem({
  className,
  value,
  children
}: AccordionItemProps) {
  const id = useId();

  return (
    <AccordionPrimitive.Item
      value={value ?? id}
      className={cx("accordion-item", className)}
    >
      {children}
    </AccordionPrimitive.Item>
  );
}

export type AccordionTriggerProps = {
  className?: string;
  children: ReactNode;
};

export function AccordionTrigger({
  className,
  children
}: AccordionTriggerProps) {
  return (
    <AccordionPrimitive.Header className={cx("accordion-header", className)}>
      <AccordionPrimitive.Trigger className={cx("accordion-trigger")}>
        {children}
        <FontAwesomeIcon
          className={cx("accordion-trigger-icon")}
          icon={faAngleDown}
        />
      </AccordionPrimitive.Trigger>
    </AccordionPrimitive.Header>
  );
}

export type AccordionContentProps = {
  className?: string;
  children: ReactNode;
};

export function AccordionContent({
  className,
  children
}: AccordionContentProps) {
  return (
    <AccordionPrimitive.Content className={cx("accordion-content")}>
      <div className={cx("accordion-content-container", className)}>
        {children}
      </div>
    </AccordionPrimitive.Content>
  );
}

/**
 * @deprecated Use AccordionItem instead
 */
Accordion.Item = AccordionItem;
/**
 * @deprecated Use AccordionTrigger instead
 */
Accordion.Trigger = AccordionTrigger;
/**
 * @deprecated Use AccordionContent instead
 */
Accordion.Content = AccordionContent;
