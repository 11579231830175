import {
  faCircleCheck,
  faCircleInfo,
  faExclamationCircle,
  faExclamationTriangle,
  faXmark,
  faXmarkCircle
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames/bind";
import { ComponentProps } from "react";

import styles from "./Alert.module.scss";

const cx = classNames.bind(styles);

type Props = {
  /**
   * If present, will add a close button.
   * Emits when the close button was clicked
   */
  onClose?: () => void;
  /** An alert title displayed on top */
  title?: string;
  /** Changes the alert icon and color to convey the nature of the alert */
  variant?: "info" | "warning" | "error" | "success";
} & ComponentProps<"div">;

function getIcon(variant: Props["variant"]) {
  if (variant === "info") return faCircleInfo;
  if (variant === "warning") return faExclamationTriangle;
  if (variant === "error") return faXmarkCircle;
  if (variant === "success") return faCircleCheck;

  return faExclamationCircle;
}

export function Alert({
  onClose,
  title,
  variant = "info",
  className,
  children,
  ...rest
}: Props) {
  const icon = getIcon(variant);

  return (
    <div
      role="alert"
      className={cx("alert-box", className, [variant])}
      {...rest}
    >
      <FontAwesomeIcon
        fixedWidth
        className={cx("alert-icon", { "align-center": title })}
        icon={icon}
      />

      <div className={cx("alert-body")}>
        {title && <h3 className={cx("alert-body-title")}>{title}</h3>}
        {children}
      </div>

      {onClose && (
        <button className={cx("alert-close-button")} onClick={onClose}>
          <FontAwesomeIcon fixedWidth icon={faXmark} />
        </button>
      )}
    </div>
  );
}
