"use client";

import { findChildren, usePrevious } from "@hdir/utility";
import {
  ExpandedState,
  getCoreRowModel,
  getExpandedRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  PaginationState,
  SortingState,
  Updater,
  useReactTable
} from "@tanstack/react-table";
import classNames from "classnames/bind";
import { PropsWithChildren, useEffect, useId, useMemo, useState } from "react";

import { getColumnsWithActions } from "./helpers/getColumnsWithActions";
import { getInitItemsPerPage } from "./helpers/getInitItemsPerPage";
import { useExpanding } from "./helpers/useExpanding";
import { useRowSelection } from "./helpers/useRowSelection";
import styles from "./Table.module.scss";
import { TableBody } from "./TableBody/TableBody";
import { TableEmptyInternal } from "./TableEmpty/TableEmptyInternal";
import { SrOnlyTableCaption } from "./TableHeader/SrOnlyTableCaption";
import { TableHeader } from "./TableHeader/TableHeader";
import { TablePaginator } from "./TablePaginator/TablePaginator";
import { PaginationItem, Row, TableProps } from "./tableProps";

const cx = classNames.bind(styles);

const DefaultPaginationItems: PaginationItem[] = [
  { value: 10, title: "10" },
  { value: 15, title: "15" },
  { value: 20, title: "20" },
  { value: 50, title: "50" },
  { value: 99_999_999, title: "Vis alle" }
];

/**
 * IMPORTANT: Hvis du f.eks bruker inline editing i celler eller andre komponenter med state,
 * pass på å bruke useMemo på columns definisjonen. Ellers vil komponentene destroyes og gjenskapes
 * slik at du mister state.
 */
// eslint-disable-next-line max-statements
export function Table<T>(props: TableProps<T> & PropsWithChildren) {
  const {
    rows = [],
    columns,
    title,
    caption,
    pageIndex = 0,
    isSelectable,
    hideFooter,
    children
  } = props;
  const { getTable, renderExpandable } = props;

  // ID handling
  const defaultId = useId();
  const tableId = props.id ?? defaultId;

  const getRowId = useMemo(
    () =>
      renderExpandable || isSelectable
        ? (row: T) => String(props.getRowId(row))
        : undefined,
    [renderExpandable, isSelectable, props]
  );

  // Expanding
  const { expandedState, getExpandedRowIds, getRowCanExpand } =
    useExpanding(props);

  function onExpandedChange(expandFn: Updater<ExpandedState>) {
    if (typeof expandFn === "function") {
      const { expandedRowIds, expanded, collapsed } =
        getExpandedRowIds(expandFn);

      const expandedRows = expandedRowIds
        .map((id) => table.getRowModel().rowsById[id])
        .filter((value): value is Row<T> => !!value);

      props.onExpandedRowsChange?.(expandedRows);

      const changedRow =
        table.getRowModel().rowsById[(expanded ?? collapsed) as string];

      if (expanded) props.onRowExpanded?.(changedRow);
      if (collapsed) props.onRowCollapsed?.(changedRow);
    }
  }

  // Selection
  const {
    enableMultiRowSelection,
    enableRowSelection,
    onRowSelectionChange,
    rowSelection
  } = useRowSelection(props);

  // Sorting
  const [sorting, setSorting] = useState<SortingState>([]);

  // Pagination
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex,
    pageSize: getInitItemsPerPage(props)
  });

  // Other
  function handleItemsPerPageChange(itemsPerPage: number) {
    table.setPageSize(itemsPerPage);
    props.onItemsPerPageChanged?.(itemsPerPage);
  }

  const columnsWithActions = useMemo(
    () => getColumnsWithActions(props, columns),
    [columns, props]
  );

  const previousRows = usePrevious(rows);
  // Fikse og evt fjerne når depricated .notasjon ikke er i bruk lenger.
  // Vet ikke om dette fungerer med begge notasjonene, må Table.Empty og Table.Toolbar også "finnes"?
  const emptyElement = findChildren(children, TableEmpty);
  const toolbarElement = findChildren(children, TableToolbar);

  // Tanstack table integration
  const table = useReactTable({
    data: rows,
    columns: columnsWithActions,
    state: {
      expanded: expandedState,
      sorting,
      rowSelection,
      pagination
    },
    getCoreRowModel: getCoreRowModel(),
    getRowId,

    // Selection
    enableRowSelection,
    enableMultiRowSelection,
    onRowSelectionChange,

    // Sorting
    getSortedRowModel: getSortedRowModel(),
    onSortingChange: setSorting,

    // Expanding
    getSubRows: props.getSubRows,
    getExpandedRowModel: getExpandedRowModel(),
    onExpandedChange,
    getRowCanExpand,

    // Pagination
    getPaginationRowModel: getPaginationRowModel(),
    onPaginationChange: setPagination,

    /**
     * When true, will reset the page index when data changes, causing a table rerender.
     */
    autoResetPageIndex: false
  });

  /**
   * Reset page index whenever data length have changed due to filtering or searching
   * Nescessary because autoResetPageIndex is false.
   */
  useEffect(() => {
    if (rows.length !== previousRows?.length) {
      table.resetPageIndex();
    }
  }, [rows, previousRows, table]);

  /**
   * Need to update page size when rows change (typically need data loaded) so that it always
   * shows all the rows.
   */
  useEffect(() => {
    if (hideFooter) table.setPageSize(rows.length);
  }, [hideFooter, rows, table]);

  useEffect(() => {
    getTable?.(table);
  }, [getTable, table]);

  useEffect(() => {
    if (!title && !caption) {
      // eslint-disable-next-line no-console
      console.warn(
        "You havent provided a title or caption. If at least one of them is included this will be set to a sr-only caption to help accessibility"
      );
    }
  }, [title, caption]);

  return (
    <div className={cx("table-container", props.className)}>
      {(title || toolbarElement) && (
        <div className={cx("design-table-toolbar")}>
          {title}
          <div>{toolbarElement}</div>
        </div>
      )}

      <table className={cx("design-table")} id={tableId}>
        <SrOnlyTableCaption
          totalRowsCount={rows.length}
          pageSize={pagination.pageSize}
          hideFooter={hideFooter}
          headerGroups={table.getHeaderGroups()}
          caption={caption}
          title={title}
        />

        <TableHeader table={table} />
        {rows.length === 0 && (
          <TableEmptyInternal colSpan={table.getAllColumns().length}>
            {emptyElement}
          </TableEmptyInternal>
        )}

        {rows.length > 0 && (
          <TableBody
            table={table}
            setRowClassName={props.setRowClassName}
            renderExpandable={props.renderExpandable}
            onRowDoubleClick={
              props.isSelectable ? props.onRowDoubleClick : undefined
            }
          />
        )}
      </table>

      {rows.length > 0 && !hideFooter && (
        <TablePaginator
          activePage={pagination.pageIndex}
          paginationItems={props.paginationItems ?? DefaultPaginationItems}
          itemsPerPage={pagination.pageSize}
          itemsCount={rows.length}
          onPageClick={table.setPageIndex}
          onItemsPerPageChange={handleItemsPerPageChange}
          tableId={tableId}
          seperatedFromTable={false}
        />
      )}
    </div>
  );
}

export function TableEmpty({ children }: PropsWithChildren) {
  return <>{children}</>;
}

export function TableToolbar({ children }: PropsWithChildren) {
  return <>{children}</>;
}

/**
 * @deprecated Use TableEmpty instead
 */
Table.Empty = TableEmpty;

/**
 * @deprecated Use TableToolbar instead
 */
Table.Toolbar = TableToolbar;
