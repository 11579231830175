import { flexRender, Row, Table } from "@tanstack/react-table";
import classNames from "classnames/bind";
import { Fragment } from "react";

import { TableProps } from "../tableProps";
import styles from "./TableBody.module.scss";
import { TableExpandableRow } from "./TableExpandableRow";
const cx = classNames.bind(styles);

type Props<T> = Pick<TableProps<T>, "renderExpandable"> & {
  table: Table<T>;
  setRowClassName?: (row: Row<T>) => string | undefined;
  onRowDoubleClick?: (row: Row<T>) => void;
};

export function TableBody<T>({
  table,
  renderExpandable,
  setRowClassName,
  onRowDoubleClick
}: Props<T>) {
  function handleDoubleClick(row: Row<T>) {
    if (row.getCanSelect()) {
      onRowDoubleClick?.(row);
    }
  }

  return (
    <tbody>
      {table.getRowModel().rows.map((row, index) => (
        <Fragment key={row.id}>
          <tr
            className={cx(
              "design-table-row",
              { selected: row.getIsSelected() },
              { "row-selectable": !renderExpandable && row.getCanSelect() },
              setRowClassName?.(row) ? [setRowClassName(row)] : undefined
            )}
            /**
             * Rows can only be selected on row click when there is no expandable.
             * Then the user has to click on the checkbox to avoid click collisions
             */
            onClick={() =>
              !renderExpandable && row.getCanSelect()
                ? row.toggleSelected()
                : undefined
            }
            onDoubleClick={() => handleDoubleClick(row)}
          >
            {row.getVisibleCells().map((cell) => (
              <td
                className={cx("design-table-row-cell", {
                  first: index === 0,
                  "sub-row": row.depth > 0
                })}
                key={cell.id}
              >
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </td>
            ))}
          </tr>

          {renderExpandable && (
            <TableExpandableRow renderExpandable={renderExpandable} row={row} />
          )}
        </Fragment>
      ))}
    </tbody>
  );
}
