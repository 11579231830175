import { Checkbox } from "../../FormElements/Checkbox/Checkbox";
import { TableExpandableButton } from "../TableExpandableButton/TableExpandableButton";
import { Column, TableProps } from "../tableProps";

export function getColumnsWithActions<T>(
  props: TableProps<T>,
  columns: Column<T>[]
): Column<T>[] {
  const selectableColumn = getSelectableColumns<T>(props);
  const expandableColumn = getExpandableColumn<T>(props);

  return [...expandableColumn, ...selectableColumn, ...columns];
}

export function getExpandableColumn<T>(props: TableProps<T>): Column<T>[] {
  if (!(props.renderExpandable || props.getSubRows)) return [];

  return [
    {
      id: "expand",
      size: 50,
      enableSorting: false,
      meta: {
        isPlaceholder: true
      },
      cell: ({ row }) =>
        row.getCanExpand() && <TableExpandableButton row={row} />
    }
  ];
}

export function getSelectableColumns<T>(props: TableProps<T>): Column<T>[] {
  if (!props.isSelectable) return [];

  return [
    {
      id: "select",
      size: 50,
      enableSorting: false,
      meta: {
        isPlaceholder: true
      },
      header: ({ table }) =>
        props.multiselect && (
          <Checkbox
            style={{ padding: 0 }}
            name="select-all"
            {...{
              checked: table.getIsAllRowsSelected(),
              onChange: table.getToggleAllRowsSelectedHandler()
            }}
            hideLabel
            label="Select all"
            value="all"
          />
        ),
      cell: ({ row }) => (
        <Checkbox
          style={{ padding: 0 }}
          name={`select-row-${row.id}`}
          {...{
            checked: row.getIsSelected(),
            disabled: !row.getCanSelect(),
            onChange: row.getToggleSelectedHandler()
          }}
          hideLabel
          label={`Select row ${row.id}`}
          value={`select-row-${row.id}`}
        />
      )
    }
  ];
}
