"use client";

import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Listbox, ListboxButton, ListboxOptions } from "@headlessui/react";
import * as Popover from "@radix-ui/react-popover";
import classNames from "classnames/bind";
import { useId } from "react";

import { SelectedItems } from "../../Autocomplete/SelectedItems/SelectedItems";
import { InputWrapperProps } from "../../props/inputProps";
import { ItemValueProps } from "../../props/itemProps";
import { InputWrapper } from "../InputWrapper/InputWrapper";
import { useFieldValue } from "../useFieldValue";
import styles from "./Select.module.scss";
import { SelectOption } from "./SelectOption";

const cx = classNames.bind(styles);

export type ItemSelectorProps<T> = T extends object
  ? {
      /**
       * Function that takes an item object and returns the label of the option
       *
       * Required when items are objects
       */
      getDisplayName: (item: T) => string;
    }
  : {
      getDisplayName?: (item: T) => string;
    };

export type SelectProps<T> = ItemValueProps<T> &
  ItemSelectorProps<T> &
  Omit<
    InputWrapperProps,
    "errorId" | "onChange" | "htmlFor" | "defaultValue"
  > & {
    /**
     * The name used when using this component inside a form.
     */
    name?: string;
    /**
     * Describing text to display in the input field before the user has input anything.
     */
    placeholder?: string;
  };

// eslint-disable-next-line max-statements
export function Select<T>({
  onChange: onChangeFromProps,
  defaultValue: defaultValueFromProps,
  children,
  label,
  name,
  placeholder,
  className,
  description,
  hideLabel,
  fullWidth = true,
  error,
  disabled,
  required,
  getDisplayName,
  multiselect,
  value: valueFromProps
}: SelectProps<T>) {
  const fieldId = useId();
  const errorId = useId();
  const selectedItemsId = useId();

  const { value, onChange } = useFieldValue({
    onChange: onChangeFromProps,
    value: valueFromProps,
    defaultValue: defaultValueFromProps,
    multiselect
  });

  const defaultValue = (() => {
    if (multiselect) return [] as T;
    if (getDisplayName) return {} as T;
    return "" as T;
  })();

  function hasNoOptionsSelected() {
    if (!value) return true;

    if (multiselect && Array.isArray(value) && value.length === 0) {
      return true;
    }
    return !!(getDisplayName && Object.keys(value).length === 0);
  }

  const showPlaceholder = hasNoOptionsSelected() || multiselect;

  const selectedItems = (() => {
    if (!value) return null;

    if (!multiselect) {
      if (getDisplayName) {
        return getDisplayName(value as T);
      }
      return String(value);
    }

    if (!Array.isArray(value)) {
      throw new TypeError(
        "Should be array if multiselect true and one or more option is selected."
      );
    }

    if (getDisplayName) {
      return value.map((option) => getDisplayName(option)).join(", ");
    }
    return value.join(", ");
  })();

  return (
    <InputWrapper
      className={className}
      label={label}
      disabled={disabled}
      fullWidth={fullWidth}
      description={description}
      htmlFor={fieldId}
      hideLabel={hideLabel}
      error={error}
      errorId={errorId}
      required={required}
    >
      {multiselect && (
        <SelectedItems
          getDisplayName={getDisplayName || String}
          id={selectedItemsId}
          selectedItems={value as T[]}
          onRemove={(item) => {
            if (Array.isArray(value)) {
              onChange(value.filter((v) => v !== item));
            }
          }}
          disabled={disabled}
        />
      )}

      <Listbox
        value={value || defaultValue}
        onChange={onChange}
        name={name}
        disabled={disabled}
        multiple={multiselect}
        as={"div"}
        className={cx("full-width")}
        aria-describedby={multiselect ? selectedItemsId : ""}
      >
        {({ open }) => (
          <Popover.Root open={open}>
            <Popover.Trigger asChild>
              <ListboxButton
                id={fieldId}
                aria-label={label as string}
                className={cx("select-button", { error }, { open })}
              >
                <span
                  className={cx("ellipsis", "min-height", {
                    placeholder: showPlaceholder
                  })}
                >
                  {showPlaceholder ? placeholder : selectedItems}
                </span>
                <div className={cx("icon-right")}>
                  <FontAwesomeIcon
                    className={cx("caret-icon", { open })}
                    icon={faAngleDown}
                  />
                </div>
              </ListboxButton>
            </Popover.Trigger>

            <Popover.Portal>
              <Popover.Content
                align="start"
                role="presentational"
                style={{ width: "var(--radix-popover-trigger-width)" }}
                onOpenAutoFocus={(event) => event.preventDefault()}
              >
                <ListboxOptions className={cx("select-options")}>
                  {children}
                </ListboxOptions>
              </Popover.Content>
            </Popover.Portal>
          </Popover.Root>
        )}
      </Listbox>
    </InputWrapper>
  );
}

/**
 * @deprecated Use SelectOption instead
 */
Select.Option = SelectOption;
