import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SortDirection } from "@tanstack/react-table";

type Props = {
  sort: SortDirection | false;
};

export function Sorticon({ sort }: Props) {
  return (
    <>
      {sort === "asc" && (
        <FontAwesomeIcon aria-hidden="true" icon={faCaretUp} size="xs" />
      )}
      {sort === "desc" && (
        <FontAwesomeIcon aria-hidden="true" icon={faCaretDown} size="xs" />
      )}
      {/** To take up empty space: (icon is just because its required) */}
      {!sort && (
        <FontAwesomeIcon
          style={{ visibility: "hidden" }}
          aria-hidden="true"
          icon={faCaretDown}
          size="xs"
        />
      )}
    </>
  );
}
