import { useContext } from "react";

import { translation, UiContext } from "./uiContext";
import { uiContext } from "./UiContextProvider";

export function useUiContext(): UiContext {
  const context = useContext(uiContext);

  return context ?? { translation: translation.nb };
}
