import { assertArray, getDifference, usePrevious } from "@hdir/utility";
import { useCallback, useEffect, useRef, useState } from "react";

import { useFieldValue } from "../FormElements/useFieldValue";
import { AutocompleteProps } from "./autocompleteProps";
import { isSelected } from "./isSelected";
import { useFilteredItems } from "./useFilteredItems";
import { useKeyboardSelect } from "./useKeyboardSelect";

// eslint-disable-next-line max-statements
export const useAutocomplete = <T>({
  items,
  value: valueFromProps,
  defaultValue,
  getDisplayName = String,
  getItemId: getItemIdFromProps,
  multiselect,
  onRemoved,
  onChange: onChangeFromProps
}: AutocompleteProps<T>) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [isOpen, setIsOpen] = useState(false);
  const { value, onChange } = useFieldValue({
    multiselect,
    value: valueFromProps,
    defaultValue,
    onChange: onChangeFromProps
  });
  const prevValue = usePrevious(value);

  const [query, setQuery] = useState<string>(() => {
    const initValue = defaultValue ?? valueFromProps;
    if (Array.isArray(initValue) || !initValue) return "";
    return getDisplayName(initValue) ?? "";
  });

  const filteredItems = useFilteredItems(items, value, query, getDisplayName);

  const onQueryChange = useCallback(
    (query: string) => {
      setIsOpen(true);
      setQuery(query);

      if (!multiselect && query.length === 0) {
        onChange(null);
      }
    },
    [multiselect, onChange]
  );

  const getItemId = useCallback(
    (item: T) =>
      getItemIdFromProps
        ? `option-${getItemIdFromProps(item)}`
        : `option-${String(item)}`,
    [getItemIdFromProps]
  );

  const onSelectedValue = useCallback(
    (item: T) => {
      if (multiselect) {
        assertArray(value);
        if (isSelected(value, item, getItemId)) {
          const newItems = value.filter(
            (v) => getItemId(v) !== getItemId(item)
          );
          onChange(newItems);
        } else {
          onChange([...value, item]);
        }
        onQueryChange("");
      } else {
        onChange(item);
        onQueryChange(getDisplayName(item));
        setIsOpen(false);
      }
    },
    [getDisplayName, multiselect, onChange, onQueryChange, getItemId, value]
  );

  const { activeIndex, activeDescendant } = useKeyboardSelect(
    isOpen,
    filteredItems,
    onSelectedValue,
    setIsOpen,
    getItemId,
    inputRef.current
  );

  useEffect(() => {
    isOpen && inputRef.current?.focus();
  }, [isOpen]);

  useEffect(() => {
    const isControlled = valueFromProps !== undefined;

    if (isControlled) {
      if (valueFromProps === null) {
        setQuery("");
      } else if (!multiselect) {
        setQuery(getDisplayName(valueFromProps));
      }
    }
  }, [valueFromProps, multiselect, getDisplayName]);

  useEffect(() => {
    const areArrayValues = Array.isArray(value) && Array.isArray(prevValue);
    if (!(multiselect && areArrayValues)) return;

    const didRemoveValue = value.length < prevValue.length;
    if (didRemoveValue) {
      const removedItems = getDifference(value, prevValue, getItemId);
      onRemoved?.(removedItems);
    }
  }, [value, prevValue, multiselect, getItemId, onRemoved]);

  function onRemoveValue(item: T) {
    assertArray(value);
    const newValue = value.filter((o) => getItemId(o) !== getItemId(item));
    onChange(newValue);
  }

  return {
    isOpen,
    setIsOpen,
    inputRef,
    value,
    filteredItems,
    query,
    activeDescendant,
    activeIndex,
    onSelectedValue,
    onRemoveValue,
    onQueryChange,
    getDisplayName,
    getItemId
  };
};
