export type Value<T> = T[] | T | null;

export const isSelected = <T>(
  value: Value<T>,
  item: T,
  getItemId: (option: T) => string
) => {
  if (!value) return false;

  if (Array.isArray(value)) {
    return value.some((i) => getItemId(i) === getItemId(item));
  }
  return getItemId(value) === getItemId(item);
};
