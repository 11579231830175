import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames/bind";

import { Button } from "../Button/Button";
import { useUiContext } from "../uiContext/useUiContext";
import styles from "./paginator.module.scss";
const cx = classNames.bind(styles);

function shouldDisplayPageButton(i: number, activePage: number, pages: number) {
  //Viser to sidetall til venstre og høyre for valgt side (midten av paginatoren)
  const isInRange = i < activePage + 3 && i > activePage - 3;
  //Viser de første 5 sidetallene hvis det er nok sider (i starten av paginatoren)
  const showAllFirstPages = activePage < 3 && i < 5;
  //Viser alle sidetallene om paginatoren inneholder 5 sider totalt
  const showAllLastPages = activePage > pages - 3 && i > pages - 6;

  return (
    (isInRange || showAllFirstPages || showAllLastPages) &&
    i !== pages - 1 &&
    i !== 0
  );
}
export type Props = {
  activePage: number;
  pages: number;
  className: string;
  onPageClick: (pageIndex: number) => void;
};

export function PaginationNumberButtons({
  activePage,
  pages,
  className,
  onPageClick
}: Props) {
  const { translation } = useUiContext();

  return (
    <>
      <span className="sr-only" aria-live="polite" aria-atomic>
        {translation.paginator.currentPage.replace("{0}", `${activePage + 1}`)}
      </span>
      <ul className={cx(className)}>
        <li>
          <Button
            variant="icon"
            aria-label={translation.table.paginator.previousPage}
            className={cx("design-paginator-button")}
            disabled={activePage === 0}
            onClick={() => onPageClick(activePage - 1)}
          >
            <FontAwesomeIcon fixedWidth icon={faAngleLeft} aria-hidden />
          </Button>
        </li>

        <li>
          <Button
            variant={activePage === 0 ? "icon-filled" : "icon"}
            aria-label={translation.table.paginator.firstPage}
            className={cx("design-paginator-button")}
            onClick={() => onPageClick(0)}
          >
            1
          </Button>
        </li>

        {activePage > 3 && pages > 6 && (
          <li>
            <span
              className={cx("text")}
              aria-label={translation.paginator.morePagesBetween}
            >
              ...
            </span>
          </li>
        )}

        {Array.from({ length: pages }).map(
          (_, i) =>
            shouldDisplayPageButton(i, activePage, pages) && (
              <li key={i}>
                <Button
                  variant={activePage === i ? "icon-filled" : "icon"}
                  aria-label={translation.paginator.navigateTo.replace(
                    "{0}",
                    `${i + 1}`
                  )}
                  className={cx("design-paginator-button")}
                  onClick={() => onPageClick(i)}
                >
                  {i + 1}
                </Button>
              </li>
            )
        )}
        {activePage < pages - 4 && pages > 6 && (
          <li>
            <span
              className={cx("text")}
              aria-label={translation.paginator.morePagesBetween}
            >
              ...
            </span>
          </li>
        )}
        {pages > 1 && (
          <li>
            <Button
              variant={activePage === pages - 1 ? "icon-filled" : "icon"}
              aria-label={translation.table.paginator.lastPage}
              className={cx("design-paginator-button")}
              onClick={() => onPageClick(pages - 1)}
            >
              {pages}
            </Button>
          </li>
        )}
        <li>
          <Button
            variant="icon"
            aria-label={translation.table.paginator.nextPage}
            className={cx("design-paginator-button")}
            disabled={activePage === pages - 1}
            onClick={() => onPageClick(activePage + 1)}
          >
            <FontAwesomeIcon fixedWidth icon={faAngleRight} aria-hidden />
          </Button>
        </li>
      </ul>
    </>
  );
}
