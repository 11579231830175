import {
  faAngleLeft,
  faAngleRight,
  faAnglesLeft,
  faAnglesRight
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames/bind";

import { Button } from "../Button/Button";
import { useUiContext } from "../uiContext/useUiContext";
import styles from "./paginator.module.scss";
const cx = classNames.bind(styles);

export type Props = {
  activePage: number;
  pages: number;
  className: string;
  tablePaginator?: boolean;
  onPageClick: (pageIndex: number) => void;
};

export function PaginationArrowButtons({
  activePage,
  pages,
  className,
  tablePaginator = false,
  onPageClick
}: Props) {
  const { translation } = useUiContext();
  return (
    <>
      <span className="sr-only" aria-live="polite" aria-atomic="true">
        {translation.paginator.currentPage.replace("{0}", `${activePage + 1}`)}
      </span>
      <ul className={cx(className)}>
        <li>
          <Button
            variant="icon"
            aria-label={translation.table.paginator.firstPage}
            className={
              tablePaginator
                ? cx("design-paginator-arrow-button")
                : cx("design-paginator-button")
            }
            disabled={activePage === 0}
            onClick={() => onPageClick(0)}
          >
            <FontAwesomeIcon icon={faAnglesLeft} aria-hidden />
          </Button>
        </li>

        <li>
          <Button
            variant="icon"
            aria-label={translation.table.paginator.previousPage}
            className={
              tablePaginator
                ? cx("design-paginator-arrow-button")
                : cx("design-paginator-button")
            }
            disabled={activePage === 0}
            onClick={() => onPageClick(activePage - 1)}
          >
            <FontAwesomeIcon fixedWidth icon={faAngleLeft} aria-hidden />
          </Button>
        </li>
        <li>
          <span
            className={cx("text")}
            aria-label={translation.paginator.currentPage.replace(
              "{0}",
              `${activePage + 1}`
            )}
          >
            {activePage + 1}
          </span>
        </li>
        <li>
          <Button
            variant="icon"
            aria-label={translation.table.paginator.nextPage}
            className={
              tablePaginator
                ? cx("design-paginator-arrow-button")
                : cx("design-paginator-button")
            }
            disabled={activePage === pages - 1}
            onClick={() => onPageClick(activePage + 1)}
          >
            <FontAwesomeIcon fixedWidth icon={faAngleRight} aria-hidden />
          </Button>
        </li>

        <li>
          <Button
            variant="icon"
            aria-label={translation.table.paginator.lastPage}
            className={
              tablePaginator
                ? cx("design-paginator-arrow-button")
                : cx("design-paginator-button")
            }
            disabled={activePage === pages - 1}
            onClick={() => onPageClick(pages - 1)}
          >
            <FontAwesomeIcon icon={faAnglesRight} aria-hidden />
          </Button>
        </li>
      </ul>
    </>
  );
}
