"use client";

import classNames from "classnames/bind";
import { forwardRef, NamedExoticComponent, useId } from "react";

import { InputProps } from "../../props/inputProps";
import styles from "./RadioButton.module.scss";
import { useRadioGroupContext } from "./RadioGroupContext";
const cx = classNames.bind(styles);

type Props = {
  value: string;
};

export type RadioButtonProps = Props &
  Omit<InputProps, "name" | "errorId" | "htmlFor">;

// eslint-disable-next-line react/display-name
export const RadioButton = forwardRef<HTMLInputElement, RadioButtonProps>(
  ({ label, disabled, className, hideLabel, value, ...rest }, ref) => {
    const id = useId();
    const {
      disabled: isAllDisabled,
      error,
      name,
      onChange,
      selectedValue
    } = useRadioGroupContext();
    const isDisabled = disabled || isAllDisabled;

    return (
      <div className={cx("design-radiobutton-container", className, { error })}>
        <input
          {...rest}
          className={cx("design-radiobutton-input")}
          disabled={isDisabled}
          aria-label={label as string}
          type="radio"
          id={id}
          checked={selectedValue === value}
          name={name}
          ref={ref}
          onChange={() => onChange(value)}
        />

        <div className={cx("design-radiobutton-label-container")}>
          <div className={cx("design-radiobutton-circle")}></div>
          {!hideLabel && (
            <label
              htmlFor={id}
              className={cx("design-radiobutton-label", {
                disabled: isDisabled
              })}
            >
              {label}
            </label>
          )}
        </div>
      </div>
    );
  }
);

(RadioButton as NamedExoticComponent).displayName = "RadioButton";
