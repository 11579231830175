import classNames from "classnames/bind";

import { LabelProps } from "../../props/inputProps";
import styles from "./Labels.module.scss";
const cx = classNames.bind(styles);

export function Label({
  label,
  description,
  disabled,
  htmlFor,
  className,
  required
}: LabelProps) {
  return (
    <label
      className={cx("design-label", className, { disabled })}
      htmlFor={htmlFor}
    >
      <span>
        {label}
        {required && <span className={cx({ required })}> *</span>}
      </span>
      {description && (
        <span className={cx("design-label-description")}>{description}</span>
      )}
    </label>
  );
}
