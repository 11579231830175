"use client";

import { createContext, ReactNode, useMemo } from "react";

import { translation, UiContext, UiContextLanguage } from "./uiContext";

export type UiContextProviderProps = {
  language?: UiContextLanguage;
  children: ReactNode;
};
export const uiContext = createContext<UiContext | undefined>(undefined);

export function UiContextProvider(props: UiContextProviderProps) {
  const contextValues = useMemo(
    () => ({
      translation: translation[props.language === "en" ? "en" : "nb"]
    }),
    [props.language]
  );

  return (
    <uiContext.Provider value={contextValues}>
      {props.children}
    </uiContext.Provider>
  );
}
