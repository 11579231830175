"use client";

import * as Popover from "@radix-ui/react-popover";
import { ForwardedRef, forwardRef, NamedExoticComponent, useId } from "react";

import { InputWrapper } from "../FormElements/InputWrapper/InputWrapper";
import { useUiContext } from "../uiContext/useUiContext";
import { AutocompleteInput } from "./AutocompleteInput/AutocompleteInput";
import { AutocompleteItemsList } from "./AutocompleteOptionsList/AutocompleteItemsList";
import { AutocompleteProps } from "./autocompleteProps";
import { SelectedItems } from "./SelectedItems/SelectedItems";
import { useAutocomplete } from "./useAutocomplete";
import { useMergedRef } from "./useMergedRef";

const AutocompleteWithoutRef = <T,>(
  props: AutocompleteProps<T>,
  ref: ForwardedRef<HTMLInputElement>
) => {
  const {
    multiselect,
    label,
    required,
    error,
    disabled,
    isRemovable,
    fullWidth = true
  } = props;
  const {
    inputRef,
    isOpen,
    setIsOpen,
    value,
    filteredItems,
    activeDescendant,
    activeIndex,
    onSelectedValue,
    onRemoveValue,
    query,
    onQueryChange,
    getDisplayName,
    getItemId
  } = useAutocomplete(props);

  const mergedInputRef = useMergedRef(inputRef, ref);

  const { translation } = useUiContext();
  const noResultsMessage =
    props.noResultsMessage ?? translation.autocomplete.noResults;

  const fieldId = useId();
  const errorId = useId();

  /* When using "multiselect", the selected values will be shown as chips unlike single select where
   * the selected value is the text inside the input.
   * Therefore we need to link the input to the list of chips with an `aria-describedby`.
   */
  const describedById = multiselect ? `multiselect-values-${fieldId}` : "";
  return (
    <InputWrapper
      label={label}
      fullWidth={fullWidth}
      description={props.description}
      disabled={disabled}
      htmlFor={fieldId}
      hideLabel={props.hideLabel}
      error={error}
      errorId={errorId}
      className={props.className}
      required={required}
    >
      <div style={{ width: "100%" }}>
        {multiselect && Array.isArray(value) && (
          <SelectedItems
            id={describedById}
            disabled={disabled}
            selectedItems={value}
            getDisplayName={getDisplayName}
            getItemId={getItemId}
            isRemovable={isRemovable}
            onRemove={onRemoveValue}
          />
        )}
        <Popover.Root
          open={isOpen}
          onOpenChange={(open) => setIsOpen(open && !disabled)}
        >
          <Popover.Trigger asChild>
            {/** Popover needs something to attach to, and inputRef is already used */}
            <div
              data-test="trigger"
              aria-controls="autocomplete-list"
              aria-expanded={isOpen}
              aria-haspopup={isOpen}
            >
              <AutocompleteInput
                isOpen={isOpen}
                id={fieldId}
                disabled={disabled}
                query={query}
                aria-label={props["aria-label"]}
                label={label}
                activeDescendant={activeDescendant}
                ref={mergedInputRef}
                onQueryChange={onQueryChange}
                name={props.name}
                describedBy={describedById}
                error={error}
                errorId={errorId}
              />
            </div>
          </Popover.Trigger>

          <Popover.Portal>
            <Popover.Content
              align="start"
              //We don't want the popover to be read out to the screen reader
              role="presentational"
              style={{ width: "var(--radix-popover-trigger-width)" }}
              onOpenAutoFocus={(event) => event.preventDefault()}
              onEscapeKeyDown={() => setIsOpen(false)}
            >
              <AutocompleteItemsList
                activeIndex={activeIndex}
                multiselect={multiselect}
                value={value}
                query={query}
                noResultsMessage={noResultsMessage}
                items={filteredItems}
                isRemovable={isRemovable}
                getDisplayName={getDisplayName}
                getItemId={getItemId}
                onSelect={onSelectedValue}
              />
            </Popover.Content>
          </Popover.Portal>
        </Popover.Root>
      </div>
    </InputWrapper>
  );
};

// Cause reacts types are lacking
export const Autocomplete = forwardRef(AutocompleteWithoutRef) as <T>(
  props: AutocompleteProps<T> & { ref?: ForwardedRef<HTMLInputElement> }
) => ReturnType<typeof AutocompleteWithoutRef>;

(Autocomplete as NamedExoticComponent).displayName = "Autocomplete";
