import { faAngleDown, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames/bind";
import {
  forwardRef,
  KeyboardEvent,
  NamedExoticComponent,
  ReactNode
} from "react";

import { Button } from "../../Button/Button";
import { InputProps } from "../../props/inputProps";
import { useUiContext } from "../../uiContext/useUiContext";
import styles from "./AutocompleteInput.module.scss";

const cx = classNames.bind(styles);

type AutocompleteInputProps = {
  name: string;
  isOpen: boolean;
  id: string;
  describedBy: string;
  disabled?: boolean;
  error?: ReactNode;
  errorId: string;
  activeDescendant?: string;
  query: string;
  onQueryChange: (query: string) => void;
};

type Props = InputProps<"input"> & AutocompleteInputProps;
// As reacts bad typescript support doesnt understand generic forwardRef yet (see react.d.ts)
// this eslint rule also wont work
// eslint-disable-next-line react/display-name
export const AutocompleteInput = forwardRef<HTMLInputElement, Props>(
  (props, inputRef) => {
    const {
      name,
      isOpen,
      query,
      disabled,
      id,
      activeDescendant = "",
      describedBy,
      error,
      errorId,
      onQueryChange,
      ...rest
    } = props;
    const { translation } = useUiContext();

    // We dont want the enter key to propagate to a form when selected an item in the
    // options list (when the option list is open)
    function preventEnterDefaultWhenOpen(
      event: KeyboardEvent<HTMLInputElement>
    ) {
      if (event.key === "Enter" && isOpen) {
        event.preventDefault();
      }
    }

    return (
      <div className={cx("design-autocomplete-fake-input", { error: !!error })}>
        <input
          {...rest}
          name={name}
          ref={inputRef}
          className={cx("design-autocomplete-input")}
          id={id}
          disabled={disabled}
          value={query}
          role="combobox"
          aria-describedby={describedBy}
          aria-controls="autocomplete-list"
          aria-expanded={isOpen}
          aria-haspopup={isOpen}
          aria-activedescendant={activeDescendant}
          aria-invalid={!!error}
          aria-errormessage={errorId}
          autoComplete="off"
          onKeyDown={preventEnterDefaultWhenOpen}
          onChange={(event) => onQueryChange(event.target.value)}
        />

        {!disabled && (
          <Button
            variant="icon"
            aria-label={translation.autocomplete.clear}
            tabIndex={query ? undefined : -1}
            className={cx("clear-button", { hidden: !query })}
            onClick={() => onQueryChange("")}
          >
            <FontAwesomeIcon fixedWidth icon={faXmark} />
          </Button>
        )}

        <div className={cx("design-right")}>
          <FontAwesomeIcon
            fixedWidth
            className={cx("design-caret-icon", { open: isOpen })}
            icon={faAngleDown}
          />
        </div>
      </div>
    );
  }
);

(AutocompleteInput as NamedExoticComponent).displayName = "AutocompleteInput";
