"use client";

import classNames from "classnames/bind";
import { Children, cloneElement } from "react";

import styles from "./stepper.module.scss";
import { StepperContext } from "./StepperContext";
import { StepperProps } from "./stepperProps";
import { StepperStep } from "./StepperStep";

const cx = classNames.bind(styles);

export function Stepper(props: StepperProps) {
  return (
    <div className={cx("stepper", props.className)}>
      <StepperContext.Provider value={props}>
        {Children.map(props.children, (child, index) =>
          cloneElement(child, { stepIndex: child.props?.stepIndex ?? index })
        )}
      </StepperContext.Provider>
    </div>
  );
}

/**
 * @deprecated Use StepperStep instead
 */
Stepper.Step = StepperStep;
