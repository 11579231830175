"use client";

import classNames from "classnames/bind";
import {
  ChangeEvent,
  ChangeEventHandler,
  forwardRef,
  NamedExoticComponent,
  useId,
  useState
} from "react";

import { InputProps } from "../../props/inputProps";
import { useUiContext } from "../../uiContext/useUiContext";
import { InputWrapper } from "../InputWrapper/InputWrapper";
import styles from "./TextArea.module.scss";
const cx = classNames.bind(styles);

export type TextAreaProps = Omit<InputProps<"textarea">, "fullWidth"> & {
  value?: string;
  /**
   * Refers to user feedback regarding the maximum number of characters that may be entered in the field. Should not be confused with MaxLength, which prevents the user from entering more characters.
   */
  characterCounterLimit?: number;
  onChange?: ChangeEventHandler<HTMLTextAreaElement>;
};

// eslint-disable-next-line react/display-name
export const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>(
  // eslint-disable-next-line max-statements
  (
    {
      label,
      name,
      error: _error,
      className,
      description,
      hideLabel,
      disabled,
      id,
      rows = 4,
      characterCounterLimit,
      onChange,
      required,
      ...rest
    },
    ref
  ) => {
    const { translation } = useUiContext();

    const defaultId = useId();
    const fieldId = id ?? defaultId;

    const errorId = useId();
    const characterCounterId = useId();

    const [inputValueLength, setInputValueLength] = useState<number>(
      rest?.defaultValue?.toString()?.length ?? 0
    );

    const handleOnChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
      setInputValueLength(event.target.value.length);
      if (onChange) onChange(event);
    };

    const characterCounterLimitExceeded =
      !!characterCounterLimit &&
      inputValueLength > 0 &&
      inputValueLength > characterCounterLimit;
    const characterCounterLimitExceededError =
      translation.errorMessage.exceedCharLimit
        .replace("{inputValueLength}", `${inputValueLength}`)
        .replace("{characterCounterLimit}", `${characterCounterLimit}`);

    const error =
      _error ||
      (characterCounterLimitExceeded && characterCounterLimitExceededError);

    return (
      <InputWrapper
        className={className}
        label={label}
        disabled={disabled}
        fullWidth
        description={description}
        htmlFor={fieldId}
        hideLabel={hideLabel}
        error={error}
        errorId={errorId}
        required={required}
      >
        <textarea
          className={cx("design-text-area", { error })}
          disabled={disabled}
          ref={ref}
          name={name}
          id={fieldId}
          aria-invalid={!!error}
          aria-describedby={characterCounterId}
          aria-errormessage={errorId}
          rows={rows}
          onChange={handleOnChange}
          {...rest}
        />
        {!!characterCounterLimit && (
          <span
            id={characterCounterId}
            className={cx("design-char-feedback", { error })}
          >
            {inputValueLength}/{characterCounterLimit}
          </span>
        )}
      </InputWrapper>
    );
  }
);

(TextArea as NamedExoticComponent).displayName = "TextArea";
