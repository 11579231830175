import { Item } from "@radix-ui/react-dropdown-menu";
import classnames from "classnames/bind";
import { ComponentPropsWithoutRef, ElementType, ReactNode } from "react";

import styles from "./DropdownMenu.module.scss";

const cx = classnames.bind(styles);

export type DropdownMenuItemProps<C extends ElementType> = {
  /**
   * What kind of element should DropdownMenuitem be rendered as. DropdownMenuItem will inherit props from this element type
   */
  as?: C;
  /**
   *  Flag to deactivate the menu item
   */
  disabled?: boolean;
  className?: string;
  children: ReactNode;
} & ComponentPropsWithoutRef<C>;

// Note the Generic defaulted to a "button". If no "as" prop is passed e.g., <DropdownMenuItem>Hi</DropdownMenuItem>, TS will treat the rendered element as a button and provide typings accordingly
export const DropdownMenuItem = <C extends ElementType = "button">({
  as,
  children,
  className,
  disabled,
  ...restProps
}: DropdownMenuItemProps<C>) => {
  const Component = as ?? "button";

  return (
    <Item asChild disabled={disabled}>
      <Component {...restProps} className={cx("dropdown-item", className)}>
        {children}
      </Component>
    </Item>
  );
};
