"use client";

import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames/bind";
import { Children, cloneElement, PropsWithChildren, ReactElement } from "react";

import { Link } from "../Link/Link";
import { useUiContext } from "../uiContext/useUiContext";
import styles from "./breadcrumbs.module.scss";

const cx = classNames.bind(styles);

export const Breadcrumbs = ({
  children,
  className
}: PropsWithChildren<{ className?: string }>) => {
  const childrenArray = Children.toArray(children) as ReactElement[];

  const { translation } = useUiContext();

  const childrenWithValues = childrenArray.map((child, index) => {
    const active = index === childrenArray.length - 1;
    const clonedChild = cloneElement(child, {
      as: active ? "span" : child.props.as
    });
    return (
      <li key={index} aria-current={active ? "page" : undefined}>
        {clonedChild}
      </li>
    );
  });

  if (childrenWithValues.length === 0) {
    return null;
  }

  const PreviousPageLink = childrenArray.at(-2);

  const PreviousPageLinkWithIcon = PreviousPageLink
    ? cloneElement(PreviousPageLink, {}, [
        <FontAwesomeIcon icon={faArrowLeft} key="icon" aria-hidden="true" />,
        PreviousPageLink.props.children
      ])
    : null;

  return (
    <>
      <nav
        aria-label={translation.breadcrumbs.ariaLabel}
        className={cx("breadcrumbs", "desktop", className)}
      >
        <ol>{childrenWithValues}</ol>
      </nav>

      {PreviousPageLinkWithIcon && (
        <div className={cx("mobile", className)}>
          {PreviousPageLinkWithIcon}
        </div>
      )}
    </>
  );
};

/**
 * @deprecated Use Link instead
 */
Breadcrumbs.Item = Link;
