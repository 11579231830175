"use client";

import classNames from "classnames/bind";
import { useState } from "react";

import { useUiContext } from "../../uiContext/useUiContext";
import { PaginationItem } from "../tableProps";
import styles from "./PaginatorPageSelect.module.scss";
const cx = classNames.bind(styles);

const sortUpdatedItems = (
  paginationItems: PaginationItem[],
  item: PaginationItem
): PaginationItem[] => {
  const newItems = [...paginationItems, item];
  newItems.sort((a, b) => a.value - b.value);
  return newItems;
};

type Props = {
  paginationItems: PaginationItem[];
  itemsPerPage: number;
  onItemsPerPageChange: (count: number) => void;
};

export function PaginatorPageSelect({
  paginationItems,
  itemsPerPage,
  onItemsPerPageChange
}: Props) {
  const { translation } = useUiContext();
  const [items, setItems] = useState(paginationItems);

  const aldreadyInItems = items.some((option) => option.value === itemsPerPage);
  if (!aldreadyInItems) {
    const newItems = sortUpdatedItems(paginationItems, {
      value: itemsPerPage,
      title: itemsPerPage.toString()
    });
    setItems(newItems);
  }

  return (
    <div className={cx("design-paginator-items-select-container")}>
      <span>{translation.table.paginator.itemsPerPage}</span>
      <select
        className={cx("design-paginator-items-select")}
        value={itemsPerPage}
        aria-label={translation.table.paginator.chooseItemsPerPage}
        name="elements"
        onChange={({ target }) => onItemsPerPageChange(Number(target.value))}
      >
        {items.map((items, index) => (
          <option key={index} value={items.value}>
            {items.title}
          </option>
        ))}
      </select>
    </div>
  );
}
