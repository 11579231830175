import { createContext, ReactNode, useContext } from "react";

type RadioGroupContextType = {
  error?: ReactNode;
  name: string;
  disabled: boolean;
  selectedValue?: string | null;
  onChange: (value: string) => void;
};

export const RadioGroupContext = createContext<
  RadioGroupContextType | undefined
>(undefined);

export function useRadioGroupContext() {
  const context = useContext(RadioGroupContext);
  if (context === undefined) {
    throw new Error(
      "useRadioGroupContext must be used within a RadioGroupContextProvider"
    );
  }
  return context;
}
