"use client";

import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { omit } from "@hdir/utility";
import classnames from "classnames/bind";
import { FC, forwardRef, HTMLAttributes } from "react";

import styles from "./Chip.module.scss";
const cx = classnames.bind(styles);

type CommonProps = {
  /** Sets the size of the chip
   * @default small
   */
  size?: "small" | "large";
};

type RemovableProps = {
  isRemovable: true;
  /** Aria label for the remove button, required if isRemovable */
  removeAriaLabel: string;
  /** Callback function that is called when the remove button is clicked */
  onRemoveClick: () => void;
};

type NonRemovableProps = {
  /** Whether the chip can be removed
   * @default false
   */
  isRemovable?: false;
};

type Props = CommonProps & (RemovableProps | NonRemovableProps);
export type ChipProps = Props & HTMLAttributes<HTMLSpanElement>;

export const Chip: FC<ChipProps> = forwardRef<HTMLSpanElement, ChipProps>(
  (props, ref) => {
    const {
      className,
      children,
      size = "small",
      ...rest
    } = omit(props, ["onRemoveClick", "removeAriaLabel", "isRemovable"]);

    return (
      <span
        className={cx("chip", className, size)}
        ref={ref}
        data-test="chip"
        {...rest}
      >
        {/** Needed to allow child text to have ellipsis */}
        <span className={cx("chip-child")}>{children}</span>

        {props.isRemovable && (
          <button
            className={cx("remove-button")}
            type="button"
            aria-label={`Slett ${props.removeAriaLabel}`}
            onClick={props.onRemoveClick}
          >
            <FontAwesomeIcon fixedWidth icon={faXmark} />
          </button>
        )}
      </span>
    );
  }
);

Chip.displayName = "Chip";
