import classNames from "classnames/bind";

import { InputWrapperProps } from "../../props/inputProps";
import { ErrorMessage } from "../ErrorMessage/ErrorMessage";
import { Label } from "../Labels/Label";
import styles from "./InputWrapper.module.scss";
const cx = classNames.bind(styles);

export function InputWrapper({
  hideLabel,
  fullWidth,
  error,
  errorId,
  className,
  children,
  ...rest
}: InputWrapperProps) {
  return (
    <div className={cx("design-input-wrapper", className)}>
      {!hideLabel && <Label {...rest} />}

      <div
        className={cx("design-input-element-wrapper", {
          "full-width": fullWidth
        })}
      >
        {children}
      </div>

      {error && <ErrorMessage id={errorId}>{error}</ErrorMessage>}
    </div>
  );
}
