import { useEffect, useState } from "react";

const WHITESPACE_REGEX = /\s+/g;

export const useFilteredItems = <T>(
  items: T[],
  value: T[] | T | null,
  query: string,
  getDisplayName: (item: T) => string
) => {
  const [filteredItems, setFilteredItems] = useState<T[]>([]);

  useEffect(() => {
    function getFilteredItems(query: string): T[] {
      if (query === "") return items;

      return items.filter((item) => {
        const searchName = getDisplayName(item)
          .toLowerCase()
          .replaceAll(WHITESPACE_REGEX, "");
        const searchQuery = query
          .toLowerCase()
          .replaceAll(WHITESPACE_REGEX, "");
        return searchName?.includes(searchQuery);
      });
    }

    setFilteredItems(getFilteredItems(query));
  }, [items, value, query, getDisplayName]);

  return filteredItems;
};
