"use client";

import { useWindowWidth } from "@hdir/utility";
import classNames from "classnames/bind";

import { useUiContext } from "../uiContext/useUiContext";
import { PaginationArrowButtons } from "./PaginationArrowButtons";
import { PaginationNumberButtons } from "./PaginationNumberButtons";
import styles from "./paginator.module.scss";

const cx = classNames.bind(styles);

function getPaginatorModel(
  itemsCount: number,
  itemsPerPage: number,
  activePage: number
) {
  const pages = itemsPerPage ? Math.ceil(itemsCount / itemsPerPage) : 1;
  const startIndex = activePage * itemsPerPage;
  const endIndex = Math.min(startIndex + itemsPerPage, itemsCount);
  return { pages, startIndex, endIndex };
}

export type Props = {
  itemsCount: number;
  itemsPerPage: number;
  activePage: number;
  ariaControls: string;
  onPageClick: (pageIndex: number) => void;
  className?: string;
};

export function Paginator({
  itemsPerPage,
  itemsCount,
  activePage,
  ariaControls,
  onPageClick,
  className
}: Props) {
  const { translation } = useUiContext();
  const { pages, startIndex, endIndex } = getPaginatorModel(
    itemsCount,
    itemsPerPage,
    activePage
  );
  const { mobile } = useWindowWidth();

  return (
    <nav
      className={(cx("design-paginator"), className)}
      aria-label={translation.table.paginator.type}
      aria-controls={ariaControls}
      role="navigation"
      tabIndex={0}
    >
      <span aria-live="polite" aria-atomic="true" className="sr-only">
        {translation.paginator.shownElements
          .replace("{0}", `${startIndex + 1}-${endIndex}`)
          .replace("{1}", `${itemsCount}`)}
      </span>
      {mobile ? (
        <PaginationArrowButtons
          activePage={activePage}
          pages={pages}
          onPageClick={onPageClick}
          className={cx("paginator-buttons")}
        />
      ) : (
        <PaginationNumberButtons
          activePage={activePage}
          pages={pages}
          onPageClick={onPageClick}
          className={cx("paginator-buttons")}
        />
      )}
    </nav>
  );
}
