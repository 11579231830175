"use client";

import * as RadixToggleGroup from "@radix-ui/react-toggle-group";
import classNames from "classnames/bind";

import styles from "./ToggleGroup.module.scss";
import { ToggleGroupItem } from "./ToggleGroupItem";
import { ToggleGroupProps } from "./toggleGroupProps";
const cx = classNames.bind(styles);

export function ToggleGroup({
  className,
  children,
  disabled,
  onChange,
  type = "single",
  ...rest
}: ToggleGroupProps) {
  // Required to cast to never for the types in radix to not complain since we default it to single
  return (
    <RadixToggleGroup.Root
      className={cx("design-toggle-group", className, { disabled })}
      disabled={disabled}
      type={type as never}
      onValueChange={onChange as never}
      {...rest}
    >
      {children}
    </RadixToggleGroup.Root>
  );
}
/**
 * @deprecated Use ToggleGroupItem instead
 */
ToggleGroup.Item = ToggleGroupItem;
