/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from "react";

import {
  ItemValueProps,
  MultiItemValueProps,
  SingleItemValueProps
} from "../props/itemProps";

type CombinedValueProps<T> = {
  multiselect?: boolean;
  defaultValue?: T | T[];
  value?: T[] | T | null;
  onChange?: ((item: T | null) => void) | ((items: T[]) => void);
};

/**
 * Hook that determines if a component should be considered
 * controlled or uncontrolled.
 *
 * @param valueFromProps: A controlled value. If its undefined, the component is considered unControlled
 * @param defaultValue: Init value for uncontrolled component
 * @param onChangeFromProps: The change handler from consumer component
 * @param multiselect: If the component has multiselect capabilities, we may want [] as defaultValue
 * @returns {
 *  value: Controlled or uncontrolled value
 *  onChange: Change handler that calls onChange from props and sets internal value if uncontrolled
 * }
 */
export function useFieldValue<T>(props: MultiItemValueProps<T>): {
  onChange: (values: T[]) => void;
  value: T[];
};
export function useFieldValue<T>(props: SingleItemValueProps<T>): {
  onChange: (value: T | null) => void;
  value: T | null;
};
export function useFieldValue<T>(props: CombinedValueProps<T>): {
  onChange: (values: T[] | T | null) => void;
  value: T[] | T | null;
};
export function useFieldValue<T>(props: ItemValueProps<T>) {
  const [internalValue, setInternalValue] = useState<T | T[] | null>(
    props.defaultValue ?? (props.multiselect ? [] : null)
  );
  const isControlled = props.value !== undefined;
  const value = isControlled ? props.value : internalValue;

  function onChange(value?: T | T[] | null) {
    if (!isControlled) {
      setInternalValue(value as any);
    }
    props.onChange?.(value as any);
  }

  return { onChange, value };
}
