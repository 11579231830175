import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classnames from "classnames/bind";

import { isSelected, Value } from "../isSelected";
import styles from "./AutocompleteItemsList.module.scss";
const cx = classnames.bind(styles);

type Props<T> = {
  activeIndex?: number;
  /** The active search query */
  query: string;
  value: Value<T>;
  items: T[];
  multiselect?: boolean;
  noResultsMessage: string;
  isRemovable?: (option: T) => boolean;
  getDisplayName: (option: T) => string;
  getItemId: (option: T) => string;
  onSelect: (option: T) => void;
};

export function AutocompleteItemsList<T>(props: Props<T>) {
  const {
    activeIndex,
    items,
    getItemId,
    isRemovable = () => true,
    onSelect
  } = props;

  const hasNoResults = items.length === 0 && props.query !== "";

  function handleOnSelect(item: T) {
    if (isRemovable(item)) onSelect(item);
  }

  return (
    <ul
      className={cx("design-items-list")}
      role="listbox"
      id="autocomplete-list"
      tabIndex={-1}
      aria-multiselectable={props.multiselect ? "true" : "false"}
      aria-activedescendant={String(activeIndex ?? "")}
    >
      {hasNoResults ? (
        <li className={cx("design-item")}>{props.noResultsMessage}</li>
      ) : (
        items.map((item, index) => (
          <li key={getItemId(item)}>
            <button
              id={getItemId(item)}
              className={cx("design-item", { active: activeIndex === index })}
              disabled={!isRemovable(item)}
              aria-selected={isSelected(props.value, item, getItemId)}
              role="option"
              onClick={() => handleOnSelect(item)}
            >
              <FontAwesomeIcon
                className={cx("design-item-selected")}
                size="xs"
                icon={faCheck}
              />
              {props.getDisplayName(item)}
            </button>
          </li>
        ))
      )}
    </ul>
  );
}
