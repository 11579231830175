import { createContext, ReactNode, useContext } from "react";

type CheckboxGroupContextType = {
  name: string;
  disabled: boolean;
  selectedValues: string[];
  error?: ReactNode;
  toggleValue: (value: string) => void;
};

export const CheckboxGroupContext = createContext<
  CheckboxGroupContextType | undefined
>(undefined);
export const useCheckboxGroupContext = () => useContext(CheckboxGroupContext);
