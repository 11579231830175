export type UiContextTranslation = {
  autocomplete: {
    selectedItems: string;
    clear: string;
    noResults: string;
  };
  breadcrumbs: {
    ariaLabel: string;
  };
  table: {
    expandRow: string;
    paginatorActivated: string;
    paginator: {
      type: string;
      shownRows: `${string}{0}${string}{1}${string}`;
      itemsPerPage: string;
      chooseItemsPerPage: string;
      firstPage: string;
      previousPage: string;
      nextPage: string;
      lastPage: string;
    };
    sortable: string;
  };
  paginator: {
    shownElements: `${string}{0}${string}{1}${string}`;
    navigateTo: `${string}{0}`;
    currentPage: `${string}{0}`;
    morePagesBetween: string;
  };
  modal: {
    closeButton: string;
  };
  errorMessage: {
    exceedCharLimit: `${string}{inputValueLength}${string}{characterCounterLimit}${string}`;
  };
};

const en: UiContextTranslation = {
  autocomplete: {
    selectedItems: "Selected items",
    clear: "Clear",
    noResults: "No results found"
  },

  breadcrumbs: {
    ariaLabel: "breadcrumbs"
  },

  table: {
    expandRow: "Expand row",
    paginatorActivated: "This table has multiple pages.",
    paginator: {
      type: "Pagination",
      shownRows: `Displaying {0} out of {1} rows`,
      itemsPerPage: "Items per page",
      chooseItemsPerPage: "Select the number of items to display per page",
      firstPage: "Navigate to the first page",
      previousPage: "Navigate to the previous page",
      nextPage: "Navigate to the next page",
      lastPage: "Navigate to the last page"
    },
    sortable: "Click the header buttons to sort the table."
  },

  paginator: {
    shownElements: `Displaying {0} out of {1} items`,
    navigateTo: `Navigate to page {0}`,
    currentPage: "Displaying page {0}",
    morePagesBetween: "More pages between"
  },

  modal: {
    closeButton: "Close"
  },

  errorMessage: {
    exceedCharLimit:
      "Max number of characters exceeded. {inputValueLength}/{characterCounterLimit}."
  }
};
const nb: UiContextTranslation = {
  autocomplete: {
    selectedItems: "Valgte elementer",
    clear: "Fjern",
    noResults: "Ingen resultater funnet"
  },

  breadcrumbs: {
    ariaLabel: "brødsmuler"
  },

  table: {
    expandRow: "Ekspander rad",
    paginatorActivated: "Tabellen inneholder flere sider.",
    paginator: {
      type: "Sideteller",
      shownRows: `Viser {0} av {1} rader`,
      itemsPerPage: "Elementer per side",
      chooseItemsPerPage: "Velg antall elementer som vises per side",
      firstPage: "Naviger til første side",
      previousPage: "Naviger til forrige side",
      nextPage: "Naviger til neste side",
      lastPage: "Naviger til siste side"
    },
    sortable: "Klikk på kolonnenavn-knappene for å sortere tabellen."
  },

  paginator: {
    shownElements: `Viser {0} av {1} elementer`,
    navigateTo: `Naviger til side {0}`,
    currentPage: "Viser side {0}",
    morePagesBetween: "Flere sider"
  },

  modal: {
    closeButton: "Lukk"
  },

  errorMessage: {
    exceedCharLimit:
      "For mange tegn inntastet. {inputValueLength}/{characterCounterLimit}."
  }
};
export type UiContextLanguage = "en" | "nb";
export const translation: Record<UiContextLanguage, UiContextTranslation> = {
  en,
  nb
};
export type UiContext = {
  translation: UiContextTranslation;
};
