import { faXmarkCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames/bind";
import { HTMLAttributes } from "react";

import styles from "./ErrorMessage.module.scss";
const cx = classNames.bind(styles);

export function ErrorMessage({
  id,
  children,
  className,
  ...rest
}: HTMLAttributes<HTMLSpanElement>) {
  return (
    <span
      role="alert"
      id={id}
      className={cx("design-error-message", className)}
      {...rest}
    >
      <FontAwesomeIcon
        size="lg"
        fixedWidth
        className={cx("design-checkbox-label-error-icon")}
        icon={faXmarkCircle}
      />
      {children}
    </span>
  );
}
